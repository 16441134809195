<!--  -->
<template>
<div class=''>
    <div class="pageTop">体检情况</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
  <el-divider></el-divider>

<!--  <div class="block">
&lt;!&ndash;    <span class="demonstration">带快捷选项</span>&ndash;&gt;

    <el-date-picker class="dateSelect"
        v-model="value2"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions">
      format="'yyyy 年 MM 月 dd 日','yyyy 年 MM 月 dd 日'"
      value-format="'yyyy-MM-dd','yyyy-MM-dd'"]>
    </el-date-picker>
    <div class="demonstration">值：{{ value2 }}</div>
&lt;!&ndash;   <el-calendar :range="[value2]">&ndash;&gt;
&lt;!&ndash;    </el-calendar>&ndash;&gt;

  </div>-->
  <el-form ref="form" :model="form" label-width="720px">
  <el-row>
    <div class="block">
<!--      <span class="demonstration">使用 value-format</span>-->
<!--      <div class="demonstration">值：{{ value1 }}</div>-->
      <el-date-picker
          v-model="value1"
          type="date"
          placeholder="选择开始日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
      </el-date-picker>
      <div class="inLine"><!--&#12288-->至<!--&#12288--></div>
      <el-date-picker
          v-model="value2"
          type="date"
          placeholder="选择结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
      </el-date-picker>
    </div>

<!--    <div class="block">
&lt;!&ndash;      <span class="demonstration">使用 value-format</span>&ndash;&gt;
&lt;!&ndash;      <div class="demonstration">值：{{ value2 }}</div>&ndash;&gt;
      <el-date-picker
          v-model="value2"
          type="date"
          placeholder="选择结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
      </el-date-picker>
    </div>-->
  </el-row>
  <div class="resultTitle">{{value1}}到{{value2}}期间养老院的体检情况如下</div>
    <div class="resultAll">养老院该日检查人数为{{resultValue1}}人，其中心电检查{{resultValue2}}人，血压检查{{resultValue3}}人，血氧检查{{resultValue4}}人，体脂检查{{resultValue5}}人。</div>
  </el-form>

  <el-table class="resultTable"
      :data="tableData"
            height="250"
            border
      style="width: 1200px">
    <el-table-column
        prop="date"
        label="日期"
        width="90">
    </el-table-column>
    <el-table-column
        prop="name"
        label="姓名"
        width="90">
    </el-table-column>
    <el-table-column
        prop="ECG"
        label="心电">
    </el-table-column>
    <el-table-column
        prop="pressure"
        label="血压">
    </el-table-column>
    <el-table-column
        prop="oxygen"
        label="血氧">
    </el-table-column>
    <el-table-column
        prop="fat"
        label="体脂">
    </el-table-column>
    <el-table-column
        prop="num"
        label="异常项目数量">
    </el-table-column>
    <el-table-column
        prop="detail"
        label="详情">
    </el-table-column>
  </el-table>

</div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
  /*pickerOptions: {
    shortcuts: [{
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  },*/
  value1: '',
  value2: '',
  resultValue1:'0',
  resultValue2:'0',
  resultValue3:'0',
  resultValue4:'0',
  resultValue5:'0',


};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.pageTop{
  font-size: 35px;
}
.dateSelect{
  width:500px;
}

.inLine{
  font-size: 15px;
  display: inline-block;
  word-spacing: 15px;
}
.resultTitle{
  font-size: 35px;
  margin-top: 25px;
}
.resultAll{
  font-size: 25px;
  margin-top: 25px;
}

.resultTable{
  margin-top: 25px;
}
</style>
